
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・MT】MT車を選ぶ際のポイントを解説！ホンダ・シビックが人気を集める理由とは？
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            運転操作を楽しみたい方におすすめのMT車ですが、中古車を選ぶ際は注意が必要です。中古車購入の際に失敗しないためにも、押さえるべきポイントや人気車種のシビックの特徴を知っておきましょう。
            <div class="section-title">
              <span >MT車を選ぶ際のポイントとは？購入するならシビックがおすすめ！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      MT車の購入を検討している方は、購入前に必ずチェックしておきたいポイントがあります。国産車で人気のMT車であるホンダ・シビックの人気の理由も併せて確認しておきましょう。
                    </p>
                    <h3 class="heading-number" data-number="◆">MT車を選ぶ際のチェックポイント5つ</h3>
                    <p class="u-m0 u-pb1em">
                      中古のMT車を選ぶ際にチェックしておきたいポイントは、次の5つです。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>走行距離と年式をチェックする</h4>
                    <p class="u-m0 u-pb1em">
                      MT車に限らず、中古車を購入する際は、必ず走行距離と年式をチェックしてください。中古車は走行距離が短く、年式が新しいほど高値で取引されています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      ただし、走行距離が短い車の中には、購入後にほとんど乗らないまま放置していた車も存在します。このような車は、メンテナンスがしっかりとされていないこともあります。そのため、事故歴や修理履歴も併せ、車の状態を確認する必要があります。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>クラッチの状態が良好かをチェックする</h4>
                    <p class="u-m0 u-pb1em">
                      MT車はAT車とは異なり、クラッチに傷みが生じやすいため、中古車の場合は劣化している可能性も考えられます。クラッチが摩耗した状態だとギアが入りづらくなりますので、購入前にはクラッチのつながり具合やクラッチペダルの違和感がないか、ミッションの入り具合についても併せて確認することが重要です。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/running-mt-car.jpg" alt="Image">
                  </div>
              </div>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>販売店の長期保証があるか</h4>
              <p class="u-m0 u-pb1em">
                MT車はAT車に比べて交換する部品の数も多く、車のメンテナンスが多くなる傾向にあります。そのため、なるべく長期保証サービスを提供している販売店を選ぶことが大切です。車両メンテナンスを依頼できる販売店から購入すれば、万が一の際も気軽に相談できます。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>試乗が可能か</h4>
              <p class="u-m0 u-pb1em">
                MT車の場合は、運転操作のしやすさが重要なポイントです。実際に乗ってみると、違和感を覚えるケースも少なくありません。購入前に試乗をしてみて乗り心地や運転操作性を確認することをおすすめします。
              </p>
              <p class="u-m0 u-pb1em">
                試乗ができない場合は、販売店の許可をとって静止の状態でギアを入れてみてください。実際にギアを操作してみて、オイル漏れなどの問題がないかをチェックすることが大切です。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>購入する目的が何か</h4>
              <p class="u-m0 u-pb1em">
                MT車を購入する際は、走行目的をはっきりとさせることも大切なポイントです。荷物の運搬や日常生活の移動手段として購入する場合と、本気で走りを楽しみたい場合では選ぶべき車種が異なります。また、日常の走行条件を確認することも大切です。
              </p>
              <h3 class="heading-number" data-number="◆">ホンダのシビックをおすすめする理由</h3>
              <p class="u-m0 u-pb1em">
                ホンダのシビックは、1972年に初代モデルが誕生した、ホンダの四輪車の中でも最も長い歴史を持つシリーズです。
              </p>
              <p class="u-m0 u-pb1em">
                人気のホンダのシビックは、走行性能や燃費効率のよさから高い評価を得ています。また、手頃なサイズ感と価格帯であるため、新車だけでなく、中古車としても人気があります。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                ホンダのシビックやマツダのRX-7など人気のMT車をお探しの方は、car shop MIXまでご相談ください。car shop MIXでは、国産車だけでなく、外車も含めて幅広い車種の在庫を取り揃えています。ご希望の車種がございましたら、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >中古車（MT）を購入したい方はcar shop MIXへ</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      MT車は、AT車に比べて運転操作が難しいこと、購入後のメンテナンスが必要となるケースが多いことなどを踏まえて、いくつか確認すべきポイントがあります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      しっかりリサーチすることはもちろん、試乗体験をするなどして、乗り心地や車の状態をチェックしてください。購入後に後悔しないためにも、今回ご紹介したポイントを押さえて、納得のいく一台を探すことをおすすめします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      国産のMT車の中でも人気が高いホンダのシビックは、中古車市場でも根強い人気を誇る車種です。中古のMT車の購入をお考えの方は、car shop MIXにお任せください。
                    </p>
                    <p class="u-m0 u-pb1em">
                      国産車・外車を問わず、MT車もAT車もプレミアムカーを豊富に取り揃えています。万が一、公式サイトの在庫状況にお目当ての車がなかった場合でも、豊富なネットワークを活かしてご希望の車種をお探しします。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車に関する質問などがございましたら、お気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="中古車の内装" src="@/assets/img/column-maker-mt/used-car-in.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'ホンダのシビックやマツダのRX-7など人気の中古車(MT車)はcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-06.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
