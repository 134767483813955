
<template>
    <div>
      <column-list :title="'中古車で人気のモデルやメーカー・MT車に関するコラム'" :linkList="linkList"/>
      <column-footer :title="title"/>
    </div>
  </template>
  
  
  <script>
  import ColumnFooter from "../Footer.vue";
  import ColumnList from "../Columlist.vue";

  export default {
    components: {
      ColumnFooter,
      ColumnList
    },
    props: {
      title: {
        type: String,
        default: '人気のGT-RやメルセデスAMGなどの中古車をお探しならcar shop MIXへ'
      }
    },
    data: () => ({
      linkList:[],
    }),
    created() {
      this.linkList = this.$_menu(this.commonSetting.columMenuListMakerMt);
    },
  }
  </script>
  
  
  
  <style scoped lang="scss">
  </style>
  